<!-- 废弃 -->
<template>
  <div>
    <team_manager
      v-if="view_type == 'add'"
      :match_id="match_id"
      :team_data="team_data"
      view_type="add"
      :view_type="view_type"
    ></team_manager>
    <team_manager
      v-if="view_type == 'edit'"
      :match_id="match_id"
      :team_data="team_data"
      view_type="add"
      :view_type="view_type"
    ></team_manager>
  </div>
</template>

<script>
import team_manager from "./team_manager";
export default {
  name: "add_team",
  components: {
    team_manager,
  },
  async mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query.match_id;
    console.log(this.$route.query.view_type, "?????????????");
    if (this.$route.query.view_type) {
      this.view_type = this.$route.query.view_type;
    }
  },
  data() {
    return {
      view_type: "",
      match_id: "",
      team_data: {}, //初始化数据
    };
  },
};
</script>

<style scoped></style>
